import React from "react"
import Helmet from 'react-helmet'
import { Link } from "gatsby"

const notFound = () => {
  return (
    <>
      <Helmet>
        <title>Get in Touch</title>
      </Helmet>
      <div style={{textAlign: "center", padding:"15vh 0", lineHeight: "1.5", height:'50vh'}}>
        <h3 className="versailles">Get in Touch</h3>
        <p>email us at <a href="mailto:sarah@craftboxing.com?subject=Tell Your Sons" style={{textDecoration:'underline'}}>
          sarah@craftboxing.com
        </a></p>
      </div>
    </>
  )
}

export default notFound